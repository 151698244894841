<template>
  <div v-if="SHOWS_FOOTER" class="csn-footer">
    <section class="background background-default">
      <div class="container csn-footer-container">
        <div class="row homepage-cms-list csn-footer-row">
          <div
            v-for="({ title, items }, index) in csmDictionary"
            :key="index"
            class="col-md-3"
          >
            <h4>{{ title }}</h4>
            <ul v-if="items" class="page-list csn-footer-col-list">
              <li
                v-for="({ title: itemTitle, remoteLink, routePath, urlSlug },
                index) in items"
                :key="index"
              >
                <Route :to="`${locale}${routePath}`" v-if="routePath">
                  {{ itemTitle }}
                </Route>
                <Route
                  :to="{ name: route.support, params: { slug: urlSlug } }"
                  v-else-if="urlSlug"
                >
                  {{ itemTitle }}
                </Route>
                <a :href="remoteLink" v-else-if="remoteLink">{{ itemTitle }}</a>
                <span v-else>{{ itemTitle }}</span>
              </li>
              <!--<li v-if="isSectionOneLastColumn(name)">
                <a class="csn-footer-facebook-container">
                  <img
                    src="@/assets/images/icons/general/Facebook.svg"
                    alt="facebook-svg"
                  />
                </a>
              </li>
               <li v-if="isSectionOneLastColumn(name)">
                <a class="csn-footer-facebook-user">
                  {{ t('follow_jack_wild') }}
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="background background-white csn-footer-img-container">
      <div class="csn-footer-game-vendor-list">
        <Route
          v-for="({ logoUrl, slug, name }, index) in vendorLogoList"
          :to="{ name: route.gameVendor, params: { slug } }"
          :key="index"
          :title="name"
          class="vendor-name csn-footer-vendor-name"
        >
          <img class="csn-footer-vendor-img" :src="logoUrl" :alt="name" />
        </Route>
      </div>
    </section>

    <Loader v-if="IS_GAME_VENDOR_PENDING" />
    <section v-else class="background background-default">
      <div class="container csn-footer-container csn-footer-container-vendor">
        <div
          class="homepage-vendor-section csn-footer-row csn-footer-row-vendor"
        >
          <ul class="homepage-vendor-list csn-footer-vendor-list">
            <li
              v-for="({ name, games }, slug) in GAME_VENDOR_DICTIONARY"
              :key="name"
              class="homepage-vendor-list-game csn-footer-vendor"
            >
              <div :class="getVendorClass(slug)" @click="selectVendor(slug)">
                <span
                  v-if="isMobileScreen"
                  class="csn-footer-vendor-name-mobile"
                >
                  {{ name }}
                </span>

                <Route
                  v-else
                  :to="{ name: route.gameVendor, params: { slug } }"
                  class="vendor-name csn-footer-vendor-name"
                >
                  {{ name }}
                </Route>
              </div>
              <ul
                :class="{
                  'csn-footer-vendor-game-list': selectedVendor === slug,
                }"
              >
                <li v-for="(game, slug) in games" :key="slug">
                  <Route :to="{ name: route.gameInfo, params: { slug } }">
                    {{ game.name }}
                  </Route>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section class="background background-white csn-footer-img-container">
      <div class="container">
        <p class="text-center">
          <img
            v-for="{ logoUrl, name } in paymentProviderList"
            :key="name"
            :src="logoUrl"
            :alt="name"
            class="csn-footer-payment-img"
          />
        </p>
        <div class="csn-footer-payment-img-lower-container">
          <Route
            :to="{
              name: route.support,
              params: {
                slug: 'responsible-gaming',
              },
            }"
          >
            <div class="csn-footer-18-plus-img"></div>
          </Route>
        </div>
      </div>
    </section>

    <section>
      <div
        class="container csn-footer-container csn-footer-container-description"
      >
        <div class="homepage-page-description csn-footer-row">
          <h2>{{ t('text_1') }}</h2>
          <p>{{ t('description_1') }}</p>
          <h2>{{ t('text_2') }}</h2>
          <p>{{ t('description_2') }}</p>
          <h2>{{ t('text_3') }}</h2>
          <p>{{ t('description_3') }}</p>
          <h2>{{ t('text_4') }}</h2>
          <p>{{ t('description_4_paragraph_1') }}</p>
          <p>{{ t('description_4_paragraph_2') }}</p>
        </div>
        <div class="bt-1 csn-footer-row csn-footer-border"></div>
        <div class="row csn-homepage-page-footer csn-footer-row">
          <div class="col-md-6">
            <p>{{ t('footer_left_text') }}</p>
            <p>{{ t('footer_left_ending') }}</p>
            <p>
              <a :href="mgaLink">
                <img src="@/assets/images/mga-icon.png" alt="mga" />
              </a>
            </p>
          </div>
          <div class="col-md-6">
            <p>{{ t('footer_right_text') }}</p>
            <p>
              {{ t('footer_right_ending') }}
            </p>

            <ul class="csn-footer-links">
              <li>
                <a href="https://www.begambleaware.org/">
                  begambleaware.org
                </a>
              </li>
              <li>
                <a href="https://www.gamcare.org.uk/">GameCare</a>
              </li>
              <li>
                <a href="https://www.gamblersanonymous.org/ga/">
                  Gameblers Anonymous
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { MainApi, GameApi } from '@/api'
import {
  FOOTER,
  RouteName,
  Module,
  GAME_VENDOR_DICTIONARY,
  SET_GAME_VENDOR_DICTIONARY,
  CMS_GROUP_DICTIONARY,
  SET_CMS_GROUP_DICTIONARY,
  UPDATE_VENDOR_DICTIONARY,
  IS_GAME_VENDOR_PENDING,
  SHOWS_FOOTER,
  ResponseState,
  HASH,
  EMPTY_STRING,
  EMPTY_ARRAY,
  ITEMS,
  IS_MOBILE_SCREEN,
} from '@/constants'
import { map, prop, pipe, pick, dest } from '@/helpers'

const CSN_FOOTER_VENDOR_NAME_CONTAINER = 'csn-footer-vendor-name-container'
const CSN_FOOTER_VENDOR_NAME_CONTAINER_SELECTED = `${CSN_FOOTER_VENDOR_NAME_CONTAINER}-selected`
// const OTHER = 'Other'

export default {
  name: FOOTER,
  components: {
    Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
  },
  data() {
    return {
      cmsGroupList: null,
      mgaLink: HASH,
      selectedVendor: null,
      vendorLogoList: EMPTY_ARRAY,
      paymentProviderList: EMPTY_ARRAY,
    }
  },
  computed: {
    t() {
      return this.$createComponentTranslator(FOOTER)
    },
    route: () => ({
      gameVendor: RouteName.GAME_VENDOR,
      gameInfo: RouteName.GAME_INFO,
      support: RouteName.SUPPORT,
    }),
    ...mapState(Module.GAME_VENDOR, [
      GAME_VENDOR_DICTIONARY,
      IS_GAME_VENDOR_PENDING,
    ]),
    ...mapState(Module.CMS, [CMS_GROUP_DICTIONARY]),
    ...mapState(Module.MAIN, [SHOWS_FOOTER]),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    csmDictionary() {
      return this.CMS_GROUP_DICTIONARY?.items || {}
    },
    locale() {
      const lang = this.$route?.params?.lang
      return lang ? `/${lang}` : '/en'
    },
  },
  methods: {
    ...mapActions(Module.CMS, [SET_CMS_GROUP_DICTIONARY]),
    ...mapActions(Module.VENDOR, [UPDATE_VENDOR_DICTIONARY]),
    ...mapActions(Module.GAME_VENDOR, [SET_GAME_VENDOR_DICTIONARY]),
    selectVendor(slug) {
      this.selectedVendor !== slug
        ? (this.selectedVendor = slug)
        : (this.selectedVendor = null)
    },
    getVendorClass(slug) {
      return [
        CSN_FOOTER_VENDOR_NAME_CONTAINER,
        slug === this.selectedVendor
          ? CSN_FOOTER_VENDOR_NAME_CONTAINER_SELECTED
          : EMPTY_STRING,
      ]
    },
    // isSectionOneLastColumn(name) {
    //   return name === OTHER
    // },
  },
  async created() {
    // this.importAll(
    //   require.context(
    //     '../../assets/images/payment-method',
    //     true,
    //     /\.(png|jpg|jpeg|svg)$/,
    //   ),
    // )

    this.SET_CMS_GROUP_DICTIONARY()
    this.UPDATE_VENDOR_DICTIONARY()
    this.SET_GAME_VENDOR_DICTIONARY()

    const [
      { data, state },
      { data: vendorData, state: vendorState },
      { data: paymentProviderData, state: paymentProviderState },
    ] = await Promise.all([
      MainApi.getMgaVerification(),
      GameApi.getGameVendors(),
      MainApi.getPaymentProviders(),
    ])

    if (state === ResponseState.OK && data) {
      this.mgaLink = data
    }

    if (vendorState === ResponseState.OK) {
      this.vendorLogoList = pipe(
        prop(ITEMS),
        map(pick(['logoUrl', 'slug', 'name'])),
      )(vendorData)
    }

    if (paymentProviderState === ResponseState.OK) {
      this.paymentProviderList = prop(ITEMS)(paymentProviderData)
    }
  },
}
</script>
